<template>
  <div class="navbar-container d-flex content align-items-center">
    <div class="bookmark-wrapper align-items-center flex-grow-1 ">
      <b-link
        class=""
        to="/"
      >
        <span class="brand-logo d-sm-flex d-none">
          <b-img
            :src="appLogoImageWhite"
            alt="logo"
            width="150"
          />
        </span>
        <span class="mb-0 pb-0 ml-1 font-weight-bolder font-medium-2 d-sm-none text-white">{{ userData ? userData.organisation_name : '' }}</span>

      </b-link>
      <!--      <bookmarks />-->
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
<!--      <locale />-->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BImg,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import SearchBar from './components/SearchBar.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    BImg,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    DarkToggler,
    SearchBar,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    const { appLogoImageWhite } = $themeConfig.app
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      appLogoImageWhite,
    }
  },
}
</script>
